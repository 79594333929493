<template>
  <div class="about" data-view>
    <hero-with-image
      title='we execute socially <span class="whitespace-nowrap">and environmentally</span> <span class="whitespace-nowrap">conscious experiences.</span>'
      description="with one-of-a-kind experiences, we look to combine mindful, fun, active, and educational activities to elevate your stay while at <span class='saoltext'>‘neera’</span>."
      :imageUrl="require('@/assets/experiences/activities/activities_01.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <tab-menu
      :items="activity_types.map(t => {
        return {
          ...t,
          id: t.attributes.slug
        }
      })"
      all-item-label="all activities"
      :default-tab="0"
      path-name="/experiences/activities"
      path-id="type"
      :has-all="true"
    />
    <div
      id="activities"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto mb-18 lg:mb-20"
    >
      <TransitionGroup
        name="fade"
        tag="discover-item"
        class="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-x-8 lg:gap-y-10"
      >
        <discover-item
          v-for="(item, index) in activity_list"
          :key="index"
          :image-url="
            getImageByFormat(item.attributes.cardImage || item.attributes.image)
          "
          :item-title="item.attributes.title"
          :badge="item.attributes.type.data.attributes.name"
          :item-description="item.attributes.description"
          :item-detail-path="`/experiences/activity/${item.attributes.slug}`"
          :isPin="item.attributes.pin"
        />
      </TransitionGroup>
    </div>
    <other-experiences
      data-aos="fade-up"
      exclude-path="/experiences/activities"
    />
  </div>
</template>

<script>
import { getActivityList, getActivityType } from "@/api/endpoints/activities";
import DiscoverItem from "@/components/DiscoverItem.vue";
import to from "await-to-js";
import OtherExperiences from "@/components/OtherExperiences.vue";
import HeroWithImage from "../../components/HeroWithImage.vue";
import TabMenu from "@/components/TabMenu.vue";
import AOS from "aos";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "Home",
  components: {
    DiscoverItem,
    OtherExperiences,
    HeroWithImage,
    TabMenu,
  },
  created() {
    const title = "we execute socially and environmentally conscious experiences.";
    const description =
      "with one-of-a-kind experiences, we look to combine mindful, fun, active, and educational activities to elevate your stay while at ‘neera’.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      lang: "en",
      defaultTabId: 0,
      activity_type_id: 0,
      activity_types: [],
      activity_list: [],
      all_activity_list: [],
    };
  },
  watch: {
    "$route.query.type": async function (activity_type) {
      const activity_type_id = this.activity_types.find(t => t.attributes.slug === activity_type)?.id

      if (activity_type_id) {
        this.activity_list = this.all_activity_list.filter(
          (i) => i.attributes.type.data.id == activity_type_id
        );
      } else {
        this.activity_list = this.all_activity_list;
      }
    },
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });

    if (this.$route.query.type)
      this.activity_type = this.$route.query.type;

    this.setActivityType(this.lang);
    this.setActivityList(this.lang);
  },
  methods: {
    getImageByFormat(image) {
      if (image.data) return getImageUrl(image.data);
      return "";
    },
    async setActivityType(lang) {
      const [err, response] = await to(getActivityType(lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }

      this.activity_types = response.data.data;
      if (this.defaultTabId == 0 && this.activity_types.length > 0)
        this.defaultTabId = this.activity_types[0].id;
    },
    async setActivityList(lang) {
      const [err, response] = await to(
        getActivityList(0, lang)
      );

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }

      this.all_activity_list = response.data.data;

      const activity_type_id = this.activity_types.find(t => t.attributes.slug === this.activity_type)?.id

      if (activity_type_id) {
        this.activity_list = this.all_activity_list.filter(
          (i) => i.attributes.type.data.id == activity_type_id
        );
      } else {
        this.activity_list = this.all_activity_list;
      }
    },
  },
};
</script>

<style scoped>
.about-para2 {
  color: #f0efea;
  background: #994e2e;
  border-radius: 0px 156px;
}

button {
  background: #994e2e;
  border-radius: 10px;
  color: white;
}

.title-map {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: italic;
  font-weight: 300;
  color: #994e2e;
}

.subtitle {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #994e2e;
}

.subtitle2 {
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.content-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
}

.other-experience {
  background-color: #e7e5dc;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}
</style>
